<template>
<div>
<validation-observer ref="answerQuestionForm">
    <b-form>
        <b-card no-body>
            <b-card-header>تفاصيل الإجابة</b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="6">
                        <b-form-group label="اسم الطالب" label-for="studentName">
                            <b-form-input
                                type="text"
                                id="studentName"
                                readonly
                                v-model="questionDto.studentName"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group label="اسم المجيب" label-for="responderName">
                            <b-form-input
                                type="text"
                                id="responderName"
                                readonly
                                v-model="questionDto.responderName"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group label="اسم المادة" label-for="subjectName">
                            <b-form-input
                                type="text"
                                id="subjectName"
                                readonly
                                v-model="questionDto.subjectName"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group label="تاريخ الإرسال" label-for="sendDate">
                            <b-form-input
                                type="text"
                                id="sendDate"
                                readonly
                                :value="new Date(questionDto.dateAsked).toISOString().substr(0, 19).replace('T',' ')"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group label="تاريخ الإجابة" label-for="ansDate">
                            <b-form-input
                                type="text"
                                id="ansDate"
                                readonly
                                :value="new Date(questionDto.dateAnswered).toISOString().substr(0, 19).replace('T',' ')"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group label="السؤال" label-for="question">
                            <b-form-textarea
                                id="question"
                                disabled
                                rows="8"
                                :value="questionDto.question"
                            />
                        </b-form-group>
                    </b-col>
                    
                    <b-col cols="12" md="6">
                        <b-form-group label="الجواب" label-for="answer">
                            <validation-provider
                                v-if="!questionDto.answerType"
                                #default="{ errors }"
                                name="الجواب"
                                rules="required"
                            >
                                <b-form-textarea
                                    id="answer"
                                    :state="errors.length > 0 ? false : null"
                                    rows="8"
                                    v-model="questionDto.answer"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer class="border-0 pt-0">
                <b-button variant="primary" class="mr-1" @click="answerQuestionForm">تعديل</b-button>
                <b-button variant="outline-primary" @click="backHome">تراجع</b-button>
            </b-card-footer>
        </b-card>
    </b-form>
</validation-observer>
</div>
</template>
<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardFooter,
    BButton,
    BForm
} from "bootstrap-vue";
import { mapGetters, mapActions } from 'vuex';
import { getHomeRouteForResponderUser, getUserData } from "@/auth/utils";
import { required } from '@validations';

export default {
    props: {
        quesId: String,
        videoId: String,
        id: String
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        BCard,
        BCardHeader,
        BCardBody,
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BCardFooter,
        BButton,
        BForm
    },
    data: () => ({
        required
    }),
    computed: {
        ...mapGetters(['questionDto'])
    },
    created() {
        localize('ar')
        this.getVideoQuestionDetails(this.quesId)
    },
    methods: {
        ...mapActions(['getVideoQuestionDetails', 'postQuestionAnswer']),
        answerQuestionForm() {
            this.$refs.answerQuestionForm.validate()
            .then(success => {
                 if(success) {
                    this.postQuestionAnswer(this.questionDto)
                }
            })
        },
        backHome() {
            this.$router.push(getHomeRouteForResponderUser(getUserData().role ,this.id, this.videoId, 1))
        }
    }
}
</script>
